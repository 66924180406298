import React, { useState, useEffect } from "react";

import * as humanAgentService from "V2.0/services/humanAgentService";

import { useOutletContext } from "react-router-dom";
import TabsWithBadge from "../../../common/tabsWithBadge";
import Breadcrumb from "V2.0/common/breadcrumb";

import { PhoneCallAgents } from "./Contact_medium/Phone_call";
import { WhatsappAgents } from "./Contact_medium/Whatsapp";
import {
  OfficeHoursConfigure,
  scheduleData,
} from "./common/office_hours/OfficeHoursConfigure";

function useHumanAgents(projectId) {
  const [humanAgents, setHumanAgents] = useState({
    officeHours: false,
    officeHourSlots: scheduleData,
    humanAgents: [],
  });

  const fetchHumanAgents = async (projectId) => {
    try {
      const response = await humanAgentService.getAllAgents(projectId);

      setHumanAgents(response.data); // Assuming response.data is the correct data format
    } catch (error) {
      setHumanAgents([]);
      console.error("Error fetching data: ", error);
    }
  };

  return {
    humanAgents,
    fetchHumanAgents,
  };
}

const CONTACT_MEDIUM = {
  PHONE_CALL: "Phone call",
  WHATSAPP: "Whatsapp",
};

const ContactMediumView = {
  [CONTACT_MEDIUM.PHONE_CALL]: (props) => <PhoneCallAgents {...props} />,
  [CONTACT_MEDIUM.WHATSAPP]: (props) => <WhatsappAgents {...props} />,
};

export function HumanAgentTransfer({}) {
  const { projects, activeProject, projectId, settings, refetchSettings } =
    useOutletContext();

  const { humanAgents, fetchHumanAgents } = useHumanAgents(projectId);

  const [activeContactMedium, setActiveContactMedium] = useState(
    CONTACT_MEDIUM.PHONE_CALL
  );

  const contactMediumTabs = Object.values(CONTACT_MEDIUM).map(
    (contactMedium) => ({
      name: contactMedium,
      href: "#",
      current: contactMedium === activeContactMedium,
    })
  );

  useEffect(() => {
    if (projectId) {
      fetchHumanAgents(projectId);
    }
  }, [projectId]);

  const refetchAgents = () => fetchHumanAgents(projectId);

  return (
    <div className="w-full flex flex-col items-center justify-center p-8">
      <div className="w-full flex flex-col gap-y-2">
        <div className="flex justify-between">
          <p className="font-semibold text-lg">Human Agents</p>
          <OfficeHoursConfigure
            officeHours={humanAgents.officeHours}
            officeHourSlots={humanAgents?.officeHourSlots}
            projectId={projectId}
            refetchHumanAgents={refetchAgents}
          />
        </div>
        <TabsWithBadge
          className=" border-b"
          tabs={contactMediumTabs}
          onTabChange={(e, { tab, index }) => setActiveContactMedium(tab.name)}
        />
      </div>
      <div className="w-full max-h-[calc(100vh-180px)] h-[40vh] overflow-auto py-2">
        {ContactMediumView[activeContactMedium]({
          projectId,
          agents: humanAgents,
          refetchAgents,
          settings,
          refetchSettings,
        })}
      </div>
    </div>
  );
}
