import React, { useState, useEffect } from "react";

import { Checkbox } from "V2.0/common/checkbox";
import { Description, Field, Label } from "V2.0/common/fieldset";
import { Input } from "V2.0/common/input";

import { message } from "antd";
import { useOutletContext } from "react-router-dom";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";

const acuityCalendarSettingsModel = {
  userId: {
    template: {
      name: "userId",
      label: "User Id",
      type: "text",
    },
    schema: Joi.string().required().label("User Id"),
  },
  apiKey: {
    template: {
      name: "apiKey",
      label: "API key",
      type: "text",
    },
    schema: Joi.string().required().label("API Key"),
  },
  calendarId: {
    template: {
      name: "calendarId",
      label: "Calendar Id",
      type: "text",
    },
    schema: Joi.string().allow(null, "").label("Calendar Id"),
  },
  appointmentTypeId: {
    template: {
      name: "appointmentTypeId",
      label: "Appointment type Id",
      type: "text",
    },
    schema: Joi.string().required().label("Appointment Type Id"),
  },
  SECTION: {
    template: {
      name: "SECTION",
      label: "Backup Details",
    },
  },
  firstName: {
    template: {
      name: "firstName",
      label: "First Name",
      type: "text",
    },
    schema: Joi.string().allow(null, ""),
  },
  lastName: {
    template: {
      name: "lastName",
      label: "Last Name",
      type: "text",
    },
    schema: Joi.string().allow(null, ""),
  },
  email: {
    template: {
      name: "email",
      label: "Email",
      type: "email",
    },
    schema: Joi.string().allow(null, ""),
  },
};

const acuityTemplate = Object.keys(acuityCalendarSettingsModel).map(
  (field) => acuityCalendarSettingsModel[field].template
);
const acuitySchema = Object.keys(acuityCalendarSettingsModel).reduce(
  (acc, field) => {
    if (field === "SECTION") {
      return acc;
    }

    return {
      ...acc,
      [field]: acuityCalendarSettingsModel[field].schema,
    };
  },
  {}
);

function AcuityCalendarForm(props) {
  const { settings, thirdPartySettings, updateThirdPartySettings } = props;
  const [loading, setLoading] = useState(false);

  const [realTimeBookings, setRealTimeBookings] = useState({
    calendarSearchEnabled:
      settings?.RAGSettings?.realTimeBookingSettings?.calendarSearchEnabled ||
      false,
    daysToCheckSlotsFor:
      settings?.RAGSettings?.realTimeBookingSettings?.daysToCheckSlotsFor || 2,
  });

  const [calendarSettings, setCalendarSettings] = useState({
    calendarId: "",
    userId: "",
    apiKey: "",
    appointmentTypeId: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    if (thirdPartySettings?.calendarSettings?.acuitySettings) {
      const { calendarId, userId, apiKey, appointmentTypeId, backupDetails } =
        thirdPartySettings.calendarSettings.acuitySettings;
      const { firstName, lastName, email } = backupDetails;
      setCalendarSettings({
        calendarId,
        userId,
        apiKey,
        appointmentTypeId,
        firstName,
        lastName,
        email,
      });
    }
  }, [thirdPartySettings]);

  const enableCalendarSearch = () => {
    setRealTimeBookings((prevState) => ({
      ...prevState,
      calendarSearchEnabled: !prevState.calendarSearchEnabled,
    }));
  };

  const onSave = async (data) => {
    const { firstName, lastName, email, ...rest } = data;
    const payload = {
      ...rest,
      backupDetails: {
        firstName,
        lastName,
        email,
      },
    };
    try {
      message.loading("Updating Acuity calendar settings", 1);

      const response2 = await updateThirdPartySettings(
        {
          calendarSettings: {
            calendarProvider: "Acuity",
            acuitySettings: payload,
            realTimeBookings,
          },
        },
        () => {
          message.success("Calendar settings updated");
          setLoading(false);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-2/4 border rounded-lg flex flex-col mx-auto gap-y-2 p-8">
      <div className="flex flex-col gap-y-8">
        <div>
          <Field className="flex flex-col gap-y-4">
            <Label>Allow Calendar bookings</Label>
            <div className="flex items-center gap-x-4">
              <Checkbox
                name="acuity_calendar"
                onChange={enableCalendarSearch}
                value={realTimeBookings.calendarSearchEnabled}
                checked={realTimeBookings.calendarSearchEnabled}
              />
              <Description>
                Allow booking appointments in your acuity calendar
              </Description>
            </div>
          </Field>
        </div>
        <div>
          <Field>
            <Label>Days for slots</Label>
            <Description>
              Number of days to look for an available slot
            </Description>
            <Input
              type="number"
              min="2"
              max="7"
              value={realTimeBookings.daysToCheckSlotsFor}
              onChange={(e) => {
                setRealTimeBookings((prevState) => ({
                  ...prevState,
                  daysToCheckSlotsFor: Number(e.target.value),
                }));
              }}
            />
          </Field>
        </div>
      </div>
      <Form
        template={acuityTemplate}
        inputField={calendarSettings}
        schema={acuitySchema}
        loading={loading}
        setLoading={setLoading}
        onSubmit={onSave}
        buttonInfo={{
          label: "Save Calendar settings",
          style: "entire-width",
        }}
      />
    </div>
  );
}

function AcuitySchedulingSettings(props) {
  const {
    projects,
    activeProject,
    settings,
    thirdPartySettings,
    updateThirdPartySettings,
    projectId,
  } = props;

  return (
    <div className="p-8">
      <main className="">
        <div className="">
          <div className="">
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-lg">Acuity Scheduling</p>
              <p className="font-medium text-sm text-gray-500">
                Configure for appointment Schdeuling
              </p>
            </div>
            <div className="py-10 lg:py-6">
              <div className="overflow-visible my-10">
                <div className="pb-8">
                  <AcuityCalendarForm
                    settings={settings}
                    projects={projects}
                    projectId={projectId}
                    activeProject={activeProject}
                    thirdPartySettings={thirdPartySettings}
                    updateThirdPartySettings={updateThirdPartySettings}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function AcuityCalendarScheduling() {
  const props = useOutletContext();

  return <AcuitySchedulingSettings {...props} />;
}

export default AcuityCalendarScheduling;
