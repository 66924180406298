import { useState, useMemo } from "react";
import httpService from "V2.0/services/httpService";
import authService from "V2.0/services/authService";

function useAssignedConversations(projectId, searchTerm) {
  const [state, setState] = useState({
    conversations: [],
    isLoading: true,
  });

  const setLoading = (state) => {
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: state,
      };
    });
  };

  const setConversations = (conversations, isLoading) => {
    setState({
      conversations,
      isLoading,
    });
  };

  const resetAssignedConversations = () => {
    setState({
      conversations: [],
      isLoading: false,
    });
  };

  const fetchAssignedConversations = async () => {
    setLoading(true);
    try {
      const response = await httpService.get(
        `/conversation/humanAssignedConversations/${projectId}`
      );
      if (response.status === 200) {
        setConversations(response.data.conversations, false);
      }
    } catch (e) {
      console.error("Error fetching assigned conversations");
      setLoading(false);
    }
  };

  const fetchAssignedConversationsBySearchTerm = async () => {
    setLoading(true);

    try {
      const response = await httpService.get(
        `/conversation/searchConversations/${projectId}?searchTerm=${searchTerm}`
      );
      const data = response.data;

      setConversations(data.conversations, false);
    } catch (error) {
      console.error(error);
    }
  };

  const assignedToMeConversations = useMemo(() => {
    const user = authService.getCurrentUser();

    return state.conversations.reduce(
      (acc, conversation) => {
        if (
          conversation.sourceOfConversation === "WHATSAPP" &&
          conversation.humanAgentAssignmentSettings &&
          conversation.humanAgentAssignmentSettings?.humanAgentAssigned &&
          conversation.status === 0
        ) {
          const { humanAgentAssignmentSettings } = conversation;
          const { humanAgentAssigned } = humanAgentAssignmentSettings;

          if (
            humanAgentAssigned?.email === user.email ||
            user?.isCompanyOwner
          ) {
            return [
                ...acc,
                conversation
            ]
          }
        }

        return acc;
      },
      []
    );
  }, [state.conversations]);

  return {
    ...state,
    assignedToMeConversations,
    fetchAssignedConversations,
    fetchAssignedConversationsBySearchTerm,
    resetAssignedConversations
  };
}

export { useAssignedConversations };
