import { useState, useEffect } from "react";
import Joi from "joi-browser";
import { message } from "antd";

import Form from "V2.0/components/Form";

import { updateProject } from "V2.0/services/projectService";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "V2.0/common/button";

const voiceChatSettingsModel = {
  websocketMaxOpenTime: {
    template: {
      label: "Maximum Call Time (seconds)",
      type: "number",
    },
    schema: Joi.number(),
  },
};

const voiceChatSettingsFields = Object.keys(voiceChatSettingsModel).map(
  (field) => {
    const template = voiceChatSettingsModel[field].template;
    return {
      ...template,
      name: field,
    };
  }
);
const voiceChatSettingsSchema = Object.keys(voiceChatSettingsModel).reduce(
  (acc, field) => {
    const schema = voiceChatSettingsModel[field].schema;
    return {
      ...acc,
      [field]: schema,
    };
  },
  {}
);

export function CallSettingsForm(props) {
  const { settings, fetchSettings, projectId, onClose } = props;

  const [voiceChatSettings, setVoiceChatSettings] = useState({
    websocketMaxOpenTime: 0,
  });

  useEffect(() => {
    setVoiceChatSettings({
      websocketMaxOpenTime: settings?.voiceChatSettings?.websocketMaxOpenTime,
    });
  }, [settings]);

  const [loading, setLoading] = useState(false);

  const handleSave = async (data) => {
    const payload = {
      voiceChatSettings: data
    };

    try {
      const response = await updateProject(projectId, payload);

      if (response.status === 200) {
        fetchSettings(projectId);
        message.success("Settings updated");
        onClose();
      }
    } catch (error) {
      message.error("Failed to update settings");
      console.error(error);
    }
  };

  return (
    <Form
      inputField={voiceChatSettings}
      template={voiceChatSettingsFields}
      schema={voiceChatSettingsSchema}
      onSubmit={handleSave}
      loading={loading}
      setLoading={setLoading}
      buttonInfo={{ label: "Save", style: "right-side" }}
    />
  );
}

export function CallSettings(props) {
  const { projectId, settings, fetchSettings } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="flex items-center gap-x-2 px-8 border border-gray-300 rounded-lg w-64 h-12 cursor-pointer hover:bg-gray-100"
      >
        <Cog6ToothIcon className="size-5 text-gray-400" />
        <span className="text-sm/6">Call Settings</span>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <div>
              <p>Call Settings</p>
            </div>
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogBody>
          <CallSettingsForm
            settings={settings}
            projectId={projectId}
            fetchSettings={fetchSettings}
            onClose={() => setOpen(false)}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}
