import { useState, useEffect } from "react";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDebouncedSearch() {
  const [searchValue, setSearchValue] = useState(null);

  const updateSearchTerm = (value) => {
    setSearchValue(value);
  };

  const debouncedSearchValue = useDebounce(searchValue, 500);

  return {
    searchTerm: debouncedSearchValue,
    updateSearchTerm,
  };
}
