import React, { useState, useEffect } from "react";

import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Button } from "V2.0/common/button";

import {
  Dialog,
  DialogBody,
  DialogTitle,
  DialogActions,
} from "V2.0/common/dialog";

import { OfficeHours } from "./OfficeHours";
import { Switch } from "V2.0/common/switch";

import * as humanAgentService from "V2.0/services/humanAgentService";
import { message } from "antd";

export const scheduleData = {
  monday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  tuesday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  wednesday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  thursday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  friday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  saturday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  sunday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
};

function OfficeHoursConfigure(props) {
  const { officeHours, officeHourSlots, projectId, refetchHumanAgents } = props;
  const [isOfficeHoursEnabled, setIsOfficeHoursEnabled] = useState(false);
  const [open, setOpen] = useState(officeHours);
  const [data, setData] = useState(officeHourSlots);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOfficeHoursEnabled(officeHours);
  }, [officeHours]);

  useEffect(() => {
    if (officeHourSlots) {
      const { _id, ...restSlots } = officeHourSlots;
      setData(restSlots);
    }
  }, [officeHourSlots]);

  const handleSave = () => {
    setLoading(true);

    const payload = {
      officeHours: isOfficeHoursEnabled,
      officeHourSlots: data,
    };

    humanAgentService
      .updateParentAgent(projectId, payload)
      .then((response) => {
        if (response.status === 200) {
          message.success("Office hours updated");
          refetchHumanAgents(projectId);
          setLoading(false);
          setOpen(false);
        }
      })
      .catch((e) => {
        message.error("Failed to update office hours");
        setLoading(false);
      });
  };

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <Cog6ToothIcon />
        Office Hours
      </Button>
      <Dialog size="2xl" open={open} onClose={() => setOpen(false)}>
        <DialogTitle className="flex items-center gap-x-2">
          Office Hours
          <Switch
            color="emerald"
            checked={isOfficeHoursEnabled}
            onChange={(checked) => setIsOfficeHoursEnabled(checked)}
          />
        </DialogTitle>
        <DialogBody className="border">
          <OfficeHours onSave={handleSave} data={data} setData={setData} />
        </DialogBody>
        <DialogActions>
          <Button outline onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { OfficeHoursConfigure };
