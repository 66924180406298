import React, { useEffect, useState } from "react";

import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";
import { Button } from "V2.0/common/button";

import { deleteWebhook } from "V2.0/services/webhookService";
import { Dialog, DialogActions, DialogTitle } from "V2.0/common/dialog";
import { message } from "antd";

const getTime = (data) => {
  if (data === 0) return "Never";

  const epochTime = data;
  const date = new Date(epochTime);
  const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
  return dateString;
};

function WebhookDelete(props) {
  const { webhook, projectId, fetchWebhooks, open, onClose } = props;
  const [loading, setLoading] = useState(false);

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await deleteWebhook({
        projectId,
        webhookId: webhook._id,
      });

      if (response.status === 200) {
        setLoading(false);
        message.success("Webhook deleted");
        fetchWebhooks(projectId);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      message.error("Webhook delete failed");
      console.error(error);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete {webhook.webhookName}?</DialogTitle>
      <DialogActions>
        <Button outline onClick={onClose}>
          Cancel
        </Button>
        <Button loading={loading} onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function SavedWebhooks({ projectId, webhooks, fetchWebhooks }) {
  const [openDelete, setOpenDelete] = useState({
    isOpen: false,
    item: null,
  });

  useEffect(() => {
    if (projectId) {
      fetchWebhooks(projectId);
    }
  }, [projectId]);

  const webhookColumns = [
    {
      key: "webhookName",
      label: "Name",
    },
    {
      key: "endpointUrl",
      label: "URL",
    },
    {
      key: "authRequired",
      label: "Authenticated",
      format: (data) => data.toString(),
    },
    {
      key: "createdAt",
      label: "Created at",
      format: (data) => getTime(data),
    },
  ];

  return (
    <>
      <TableComponent
        data={webhooks}
        headers={webhookColumns}
        onRowClick={() => {}}
        onDelete={(item, index) => setOpenDelete({ item, isOpen: true })}
      />
      {openDelete.isOpen && (
        <WebhookDelete
          open={true}
          onClose={() => setOpenDelete({ isOpen: false, item: null })}
          webhook={openDelete.item}
          projectId={projectId}
          fetchWebhooks={fetchWebhooks}
        />
      )}
    </>
  );
}
