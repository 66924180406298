import React from "react";
import zohoCRM from "../../images/zohoCRM.svg";
import GHL from "../../images/GHL.png";
import Twilio from "../../images/twilio.svg";
import ZapierImage from "../../images/zapier.svg";
import whatsapp from "../../images/whatsapp.svg";
import messenger from "../../images/messenger.svg";
import S3 from "../../images/s3.svg";
import Chroma from "../../images/chroma.svg";
import pinecone from "../../images/pinecone.png";
import Plivo from "V2.0/images/plivo.svg";
import Make from "V2.0/images/make.svg";
import AcuityScheduling from "V2.0/images/acuity-logo.svg";
import { useNavigate } from "react-router-dom";
import GmailLogo from "V2.0/images/gmail.png";

const ImageIcon = ({ src, alt, width }) => (
  <img src={src} alt={alt} style={{ width: `${width}px` }} />
);

function MenuItem({ name, Icon, iconProps, onClick, description }) {
  return (
    <div
      className={`flex flex-col justify-between gap-y-2 h-24 w-90 border border-gray-300 p-4 pb-4 rounded-lg cursor-pointer hover:bg-gray-100`}
      onClick={onClick}
    >
      <div className="flex items-center gap-x-2">
        {Icon ? <Icon /> : <ImageIcon {...iconProps} />}
        <span className="font-medium text-gray-600 text-sm">{name}</span>
      </div>
      {description && (
        <div>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      )}
    </div>
  );
}

function SectionComponent(props) {
  const { section } = props;
  const navigate = useNavigate();

  return (
    <div className="mt-8" key={section.title}>
      <div className="flex justify-start">
        <span className="pr-2 text-md font-medium text-gray-800 mb-3">
          {section.title}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {section.items.map((item) => (
          <MenuItem
            key={item.name}
            name={item.name}
            Icon={item.Icon}
            iconProps={item.iconProps}
            onClick={() => navigate(item.href)}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
}

const integrationSections = [
  {
    title: "Communication",
    items: [
      {
        id: "Twilio",
        name: "Twilio",
        iconProps: { src: Twilio, width: 30 },
        href: "/integrations/twilio",
        description: "Integrate Twilio for AI-powered voice interactions",
      },
      {
        id: "Plivo",
        name: "Plivo",
        iconProps: { src: Plivo, width: 40 },
        href: "/integrations/plivo",
        description: "Integrate Plivo for AI-powered voice interactions",
      },
      // {
      //   id: "GMail",
      //   name: "GMail",
      //   iconProps: { src: GmailLogo, width: 30 },
      //   href: "/integrations/gmail",
      //   description: "Integrate your AI to manage your emails",
      // },
      {
        id: "Whatsapp",
        name: "Whatsapp",
        iconProps: { src: whatsapp, width: 30 },
        href: "/integrations/whatsapp",
        description: "Integrate for AI messaging with customers",
      },
      {
        id: "Messenger",
        name: "Messenger",
        iconProps: { src: messenger, width: 30 },
        href: "/integrations/messenger",
        description: "Connect Messenger",
      },
    ],
  },
  {
    title: "Contacts & Calendars",
    items: [
      {
        id: "ZohoCRM",
        name: "Zoho CRM",
        iconProps: { src: zohoCRM, width: 40 },
        href: "/integrations/zoho-crm",
        description: "Integrate to search contacts in Zoho CRM",
      },
      {
        id: "GHL",
        name: "GoHighLevel",
        iconProps: { src: GHL, width: 30 },
        href: "/integrations/ghl",
        description: "Integrate for contacts search and book meetings",
      },
    ],
  },
  {
    title: "Automation",
    items: [
      {
        id: "Zapier",
        name: "Zapier",
        iconProps: { src: ZapierImage, width: 30 },
        href: "/integrations/zapier",
        description: "Automate your workflows with zapier",
      },
      {
        id: "Make",
        name: "",
        iconProps: { src: Make, width: 80 },
        href: "/integrations/make",
        description: "Automate your workflows with make",
      },
    ],
  },
  {
    title: "Database",
    items: [
      {
        id: "S3",
        name: "S3",
        href: "/integrations/s3",
        iconProps: { src: S3, width: 30 },
        description: "Connect to store your data in your S3 storage",
      },
      {
        id: "Pinecone",
        name: "Pinecone",
        href: "/integrations/pinecone",
        iconProps: { src: pinecone, alt: "Pinecone", width: 20 },
        description: "Connect to your Pinecone DB",
      },
      {
        name: "",
        id: "ChromaDB",
        href: "/integrations/chromadb",
        iconProps: { src: Chroma, alt: "ChromaDB", width: 80 },
        description: "Connect to your Chroma DB",
      },
    ],
  },
];

// Configuration of integrations
/**
 *
 * keeping this for future reference
 */
const integrations = [
  {
    id: "ZohoCRM",
    name: "Zoho CRM",
    logo: zohoCRM,
    height: "20px",
    description: "Connect to search your zoho contacts",
  },
  {
    id: "GHL",
    name: "GoHighLevel Account",
    logo: GHL,
    height: "20px",
    description: "Connect to search to GoHighLevel contacts",
  },
  {
    id: "Twilio",
    name: "Twilio",
    logo: Twilio,
    height: "20px",
    description: "Connect Twilio.",
  },
  {
    id: "Zapier",
    name: "Zapier",
    logo: ZapierImage,
    height: "20px",
    description: "Build automations",
  },
  {
    id: "Whatsapp",
    name: "Whatsapp",
    logo: whatsapp,
    height: "20px",
    description: "Connect whatsapp",
  },
  {
    id: "Messenger",
    name: "Messenger",
    logo: messenger,
    height: "20px",
    description: "Connect Messenger",
  },
  // {
  //   id: "GoogleCalendar",
  //   name: "Google Calendar",
  //   logo: googleCalendarImage,
  //   height:"25px",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  // },
  // {
  //   id: "Calendly",
  //   name: "Calendly",
  //   logo: calendly,
  //   height:"15px",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  // },
  // {
  //   id: "Oncehub",
  //   name: "Oncehub",
  //   logo: onceHubImage,
  //   height:"15px",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.",
  // },
];

export function Integrations() {
  return (
    <div className="w-full h-full">
      <main className="w-4/5 mx-auto h-full flex flex-col gap-y-6">
        <div className="pt-8 px-8">
          <p className="font-bold text-2xl">Integrations</p>
        </div>
        <div className="h-[calc(100vh-100px)] overflow-auto">
          <div className="flex flex-col px-8 mb-8">
            {integrationSections.map((integration) => (
              <SectionComponent section={integration} />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
