import { DatePicker } from "antd";
import { Switch } from "V2.0/common/switch";

import dayjs from "dayjs";
import { Button } from "V2.0/common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import clsx from "clsx";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function Day(props) {
  const { day, slots, isActive, onDayClick, onToggle, checked } = props;
  return (
    <div
      className={clsx(
        "flex gap-x-4 items-center cursor-pointer px-2 py-4",
        isActive && "bg-zinc-300"
      )}
      onClick={onDayClick}
    >
      <Switch
        color="emerald"
        checked={checked}
        onClick={() => {
          onToggle(!checked);
        }}
      />

      <p
        className={clsx(
          "text-sm",
          isActive ? "text-gray-700 font-semibold" : "text-gray-400"
        )}
      >
        {`${capitalizeFirstLetter(day)} (${slots})`}
      </p>
    </div>
  );
}

function Time(props) {
  const { time, autoFocus, onChange } = props;
  return (
    <DatePicker
      autoFocus={autoFocus}
      mode="time"
      picker="time"
      format="HH:mm"
      onChange={onChange}
      value={dayjs(time, "HH:mm")}
      allowClear={false}
    />
  );
}

function OfficeHours(props) {
  const { data, setData } = props;
  const [activeDay, setActiveDay] = useState("monday");

  const updateDateChecked = (dateChecked) => {
    const { day, checked } = dateChecked;
    setData((prevData) => {
      return {
        ...prevData,
        [day]: {
          ...prevData[day],
          checked,
        },
      };
    });
  };

  const addSlot = (day) => {
    setData((prevData) => {
      return {
        ...prevData,
        [day]: {
          ...prevData[day],
          slots: [
            ...prevData[day].slots,
            { startTime: "10:00", endTime: "16:00" },
          ],
        },
      };
    });
  };

  const removeSlot = (day, idx) => {
    setData((prevData) => {
      return {
        ...prevData,
        [day]: {
          ...prevData[day],
          slots: prevData[day].slots.toSpliced(idx, 1),
        },
      };
    });
  };

  const updateSlotTime = (idx, slot) => {
    setData((prevData) => {
      return {
        ...prevData,
        [activeDay]: {
          ...prevData[activeDay],
          slots: [
            ...prevData[activeDay].slots.slice(0, idx),
            slot,
            ...prevData[activeDay].slots.slice(idx + 1),
          ],
        },
      };
    });
  };

  return (
    <div className="flex rounded-lg">
      <div className="w-1/3 flex flex-col border-r">
        {Object.keys(data).map((day) => {
          const checked = data[day]?.checked;
          const slots = data[day]?.slots || [];
          return (
            <Day
              slots={slots.length}
              key={day}
              isActive={day === activeDay}
              day={day}
              checked={checked}
              onToggle={(state) => {
                updateDateChecked({
                  day,
                  checked: state,
                });
              }}
              onDayClick={() => {
                setActiveDay(day);
              }}
            />
          );
        })}
      </div>
      <div className="w-3/4 flex flex-col gap-y-2 p-2 min-h-[500px] max-h-[500px] overflow-auto">
        <div className="flex flex-col gap-y-2">
          {data[activeDay]?.slots.map((slot, idx) => {
            const { startTime, endTime } = slot;
            return (
              <div
                className="flex justify-center gap-x-4"
                key={`${slot}_${idx}`}
              >
                <div className="flex justify-center items-between gap-x-4">
                  <Time
                    time={startTime}
                    onChange={(date, dateString) =>
                      updateSlotTime(idx, { startTime: dateString, endTime })
                    }
                  />
                  <Time
                    time={endTime}
                    onChange={(date, dateString) =>
                      updateSlotTime(idx, { startTime, endTime: dateString })
                    }
                  />
                </div>

                <Button
                  className={clsx(
                    data[activeDay]?.slots.length <= 1 ? "invisible" : "visible"
                  )}
                  plain
                  onClick={() => removeSlot(activeDay, idx)}
                >
                  <XMarkIcon />
                </Button>
              </div>
            );
          })}
        </div>
        <Button className="mx-12" outline onClick={() => addSlot(activeDay)}>
          <PlusIcon /> Add slot
        </Button>
      </div>
    </div>
  );
}

export { OfficeHours };
