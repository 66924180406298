import React, { useEffect } from "react";

import { Field, Label, ErrorMessage } from "../../../common/fieldset";
import { DatePicker } from "antd";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export function StartAndEndDateField(props) {
  const { error, setError, setDate, timezone } = props;

  function onDateChange(date, dateString) {
    if (error.isError) {
      setError({
        isError: false,
        message: "",
      });
    }

    const [startDate, endDate] = dateString;

    const updatedStartDate = dayjs.tz(startDate, timezone);
    const updatedEndDate = dayjs
      .tz(endDate, timezone)
      .set("hour", 23)
      .set("minute", 59);

    setDate({
      startDate: updatedStartDate.utc().format("x"),
      endDate: updatedEndDate.utc().format("x"),
    });
  }

  return (
    <Field>
      <Label>Choose Start & End date</Label>
      <DatePicker.RangePicker
        size="medium"
        className="border-slate-200 font-medium text-xl w-full mt-4"
        maxDate={dayjs()}
        variant="outlined"
        placeholder={["Start date", "End date"]}
        onChange={onDateChange}
        status={error.isError && "error"}
      />
      {error.isError && (
        <ErrorMessage className="pt-2">{error.message}</ErrorMessage>
      )}
    </Field>
  );
}
